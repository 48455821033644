var accordionFilter = {
    accordionFilterInit: function($) {
        var $filter = $('.filter'),
            $filterItem = $filter.find('.meta'),
            $filterLi = $('.filter-block .filter > li');

        if (!$filterItem.length) {
            return;
        }

        $filterItem.on('click', function(e) {
            if($(window).width() < 1024){
                e.preventDefault();
                accordionFilterHandler.call(this);
            }
        });

        $filterLi.on('mouseover', function(e) {
            if($(window).width() >= 1024){
                $filterLi.removeClass('active-tablet');
                $(e.target).closest('li').addClass('active-tablet');
            }
        });

        $filterLi.on('mouseleave', function(e) {
            if($(window).width() >= 1024){
                $filterLi.removeClass('active-tablet');
            }
        });

        function accordionFilterHandler(){
            if($(this).closest('li').hasClass('active')){
                $(this).closest('li').removeClass('active');
                return false;
            }
            $('.filter > li').removeClass('active');
            $(this).closest('li').addClass('active');
        }

    }

};

export default accordionFilter;
var heroSlider = {
	heroSliderInit: function($) {
		var $heroSliderWrap = $('.hero-slider'),
			$heroSlides = $heroSliderWrap.find('.swiper-slide');

		if (!$heroSliderWrap.length) {
			return;
		}

		if($heroSlides.length > 1){
			var swiper = new Swiper('.hero-slider .swiper-container', {
				pagination: {
					el: '.hero-slider .swiper-pagination',
					clickable: true,
					renderBullet: function(index, className) {
					    return '<span class="' + className + '">0' + (index + 1) + '</span>';
					},
				},
				navigation: {
					nextEl: '.hero-slider .swiper-button-next',
					prevEl: '.hero-slider .swiper-button-prev',
				}
			});
		} else {
			var swiper = new Swiper('.hero-slider .swiper-container', {
				simulateTouch: false
			});

			$heroSliderWrap.addClass('single-image');
		}

	}

};

export default heroSlider;
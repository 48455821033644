var popups = {
    popupsInit: function($) {
        var $popupsWrap = $('.popup'),
            $contactsFormWrap = $('.contacts-form-holder'),
            $filesListWrap = $contactsFormWrap.find('.files-list'),
            $filesListWrap2 = $contactsFormWrap.find('.files-list-2'),
            $uploadInput = $contactsFormWrap.find('.upload-input');

        if (!$popupsWrap.length) {
            return;
        }

        document.addEventListener( 'wpcf7mailsent', function( event ) {
            if($('.form-actions').length){
                for(var i = 0; i < $('.input-file').length; i++){
                    if($('.input-file')[i].value === ''){
                        $('.input-file')[i].closest('.wpcf7-form-control-wrap').remove();
                    }
                }
            }
            setTimeout(function(){
                $('.popup .text').html('<p>' + $('.wpcf7-mail-sent-ok').text() + '</p>');
                showLightBox('#success');
                if($filesListWrap2.length){
                    $filesListWrap.html('');
                    $filesListWrap2.html('');
                    $uploadInput.closest('.input-holder').removeClass('url-error');
                }
            }, 500);
        }, false );

        document.addEventListener( 'wpcf7invalid', function( event ) {
            if($('.form-actions').length){
                $('.too-big-error').remove();
                var tooBigWrap = '<span class="too-big-error"></span>';
                for(var i = 0; i < $('.input-file').length; i++){
                    if($('.input-file')[i].classList.contains('wpcf7-not-valid')){
                        var errorBigFile = $('.input-file')[i].nextElementSibling.innerText;
                        $('.files-list .fake-input-file').eq(i).addClass('too-big').after(tooBigWrap);
                        $('.files-list .fake-input-file').eq(i).next().html(errorBigFile);
                    }
                }
            }
        }, false );

        showSuccessPopup();

        function showSuccessPopup(){
            $('form.mailpoet_form').submit(function(){
                var $successWrap = $('form.mailpoet_form .mailpoet_validate_success'),
                    checker = setInterval(function () {
                        if($successWrap.attr('style') !== '' && $successWrap.attr('style') !== 'display: block;') {
                            return;
                        }
                        if($successWrap.attr('style') === '' || $successWrap.attr('style') === 'display: block;'){
                            $('.popup .text').html('<p>' + $('#mailpoet-success-message').text() + '</p>');
                            showLightBox('#success');
                            clearInterval(checker);
                            showSuccessPopup();
                        }
                    }, 100);
            });
        }

        function showLightBox(url) {
            $.fancybox({
                href: url,
                afterLoad: function(current, previous) {
                    if (current.href.indexOf('#') === 0) {
                        jQuery(current.href).find('.close').off('click.fb').on('click.fb', function(e) {
                            jQuery.fancybox.close();
                        });
                    }
                    $(document).off('click.popup').on('click.popup', function(e) {
                        if (!$(e.target).parents('.fancybox-inner').length) {
                            if ($('html').hasClass('fancybox-lock')) {
                                jQuery.fancybox.close();
                            }
                        }
                    });
                    $('html').addClass('fancybox-lock');
                },
                afterClose: function() {
                    $('html').removeClass('fancybox-lock');
                    $(document).off('click.popup');
                }
            });
        }

    }

};

export default popups;
var programAll = {
    programAllInit: function($) {
        var $programAllBtn = $('.program-block .view-all'),
            $programRow = $('.program-block .row'),
            $body = $('body'),
            $programWrap = $('.program-block .rows-holder');

        if (!$programAllBtn.length) {
            return;
        }

        $programAllBtn.on('click', function() {
            programAllHandler.call(this);
        });

        function programAllHandler(){
            var textHide = $(this).data('hide'),
                textShow = $(this).data('show');

            $programWrap.toggleClass('active');
            if($programWrap.hasClass('active')){
                $(this).text(textHide);
            } else {
                $(this).text(textShow);
            }
        }

    }

};

export default programAll;
var matchHeight = {
    matchHeightInit: function($) {
        var $sameElem = $('.animate-block .info-holder.same');

        if (!$sameElem.length) {
            return;
        }

        $(function() {
        	$('.animate-block .info-holder.same').matchHeight({
        		byRow: false
        	});
        });

    }

};

export default matchHeight;
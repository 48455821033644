var mailPoetSubmitBtn = {
    mailPoetSubmitBtnInit: function($) {
        var $mailPoetSubmitBtnWrap = $('.mailpoet_form .mailpoet_submit');

        if (!$mailPoetSubmitBtnWrap.length) {
            return;
        }

        $mailPoetSubmitBtnWrap.wrap('<div class="submit-wrap"></div>');

    }

};

export default mailPoetSubmitBtn;
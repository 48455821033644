// You can write a call and import your functions in this file.
//
// This file will be compiled into app.js and will not be minified.
// Feel free with using ES6 here.

import submenu from './modules/submenu';
import heroSlider from './modules/hero-slider';
import burger from './modules/burger';
import scrollDown from './modules/scroll-down';
import matchHeight from './modules/match-height';
import decorLine from './modules/decor-line';
import logosSlider from './modules/logos-slider';
import blockAnimate from './modules/blocks-animate';
import stickyHeader from './modules/sticky-header';
import scrollToBlock from './modules/scroll-to-block';
import popups from './modules/popups';
import filterAll from './modules/filter-all';
import accordionFilter from './modules/accordion-filter';
import buttonsList from './modules/buttons-list';
import linksList from './modules/links-list';
import programAll from './modules/program-all';
import attachFiles from './modules/attach-files';
import matchHeightJoin from './modules/match-height-join';
import customSelect from './modules/custom-select';
import joinDecorLine from './modules/join-decor';
import calendarLinks from './modules/calendar-links';
import mailPoetSubmitBtn from './modules/mail-poet-submit-btn';
import clearPlaceholder from './modules/clear-placeholder';
import dUserAgent from './modules/browser-detect';
import clearSearch from './modules/clear-search';
import activeLanguage from './modules/active-language';
import pictureTag from './modules/picture-tag';
import followLinkSecondClick from './modules/follow-link-second-click';

( ($) => {
  'use strict';

  // When DOM is ready
  $(() => {
    submenu.submenuInit($);
    heroSlider.heroSliderInit($);
    burger.burgerInit($);
    scrollDown.scrollDownInit($);
    matchHeight.matchHeightInit($);
    decorLine.decorLineInit($);
    logosSlider.logosSliderInit($);
    blockAnimate.blockAnimateInit($);
    stickyHeader.stickyHeaderInit($);
    scrollToBlock.scrollToBlockInit($);
    popups.popupsInit($);
    filterAll.filterAllInit($);
    accordionFilter.accordionFilterInit($);
    buttonsList.buttonsListInit($);
    linksList.linksListInit($);
    programAll.programAllInit($);
    attachFiles.attachFilesInit($);
    matchHeightJoin.matchHeightJoinInit($);
    customSelect.customSelectInit($);
    joinDecorLine.joinDecorLineInit($);
    calendarLinks.calendarLinksInit($);
    mailPoetSubmitBtn.mailPoetSubmitBtnInit($);
    clearPlaceholder.clearPlaceholderInit($);
    dUserAgent.dUserAgentInit($);
    clearSearch.clearSearchInit($);
    activeLanguage.activeLanguageInit($);
    pictureTag.pictureTagInit($);
    followLinkSecondClick.followLinkSecondClickInit($);
  });

})(jQuery);
var clearSearch = {
    clearSearchInit: function($) {
        var $searchForm = $('.search-form'),
            $clearSearchBtn = $searchForm.find('.search-clear'),
            $searchInput = $searchForm.find('.search-input'),
            $textResult = $searchForm.find('.result-count-text');

        if (!$clearSearchBtn.length) {
            return;
        }

        $clearSearchBtn.on('click', function(){
            $searchInput.val('');
            $searchForm.removeClass('active');
            $textResult.css('display', 'none');
        });

        $searchInput.on('keyup', function(){
            if($searchInput.val() != ''){
                $searchForm.addClass('active');
                $textResult.css('display', 'inline-block');
            } else {
                $searchForm.removeClass('active');
            }
        });

    }

};

export default clearSearch;
var clearPlaceholder = {
    clearPlaceholderInit: function($) {
        var $mailInput = $('form.mailpoet_form .mailpoet_text'),
            mailInputPlaceholder = $mailInput.attr('placeholder');

        if (!$mailInput.length) {
            return;
        }

        mailInputPlaceholder = mailInputPlaceholder.replace('*', '');
        $mailInput.attr('placeholder', mailInputPlaceholder);

    }

};

export default clearPlaceholder;
var linksList = {
    linksListInit: function($) {
        var $linksListBtn = $('.links-filter-list .dot-btn'),
            $linksListHolder = $('.links-holder'),
            $body = $('body');

        if (!$linksListBtn.length) {
            return;
        }

        $linksListBtn.on('click', function() {
            linksListHandler.call(this);
        });

        $body.on('touchstart click', function(e){
            if($(window).width() < 768){
                if(!$(e.target).parents('.links-holder').length){
                    $linksListHolder.removeClass('active');
                }
            }
        });

        function linksListHandler(){
          $(this).closest('.links-holder').toggleClass('active');
        }

    }

};

export default linksList;
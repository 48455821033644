var dUserAgent = {
    dUserAgentInit: function($) {
        var browser = navigator.userAgent.toLowerCase();
        if((navigator.userAgent.indexOf('Trident') != -1 && navigator.userAgent.indexOf('MSIE') == -1) || navigator.userAgent.indexOf('Edge') !== -1){
            $('body').addClass('ms');
        }
    }

};

export default dUserAgent;
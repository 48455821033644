var joinDecorLine = {
    joinDecorLineInit: function($) {
        var $joinDecorLineWrap = $('.join-type-block .item');

        if (!$joinDecorLineWrap.length) {
            return;
        }

        joinDecorLineHandler();

        $(window).resize(joinDecorLineHandler);

        function joinDecorLineHandler(){
            setTimeout(function(){
                $joinDecorLineWrap.each(function(indx, element){
                    var $joinDecorLineWrapWidth = $(element).find('.decor').width(),
                        $joinFakeDecor = $(element).find('.fake-decor');
                    $joinFakeDecor.css('left', $joinDecorLineWrapWidth + 16);
                    if($joinFakeDecor.closest('.item.active').length){
                        $joinFakeDecor.css('opacity', '1');
                    }
                });
            }, 1000);
		}

    }

};

export default joinDecorLine;
var logosSlider = {
    logosSliderInit: function($) {
        var $logosSliderWrap = $('.logos-slider'),
        	logoSlider = $('.logos-block');

        if (!$logosSliderWrap.length) {
            return;
        }

        logoSlider.each(function(indx, element){
			var btnNext = $(element).find('.swiper-button-next'),
				btnPrev = $(element).find('.swiper-button-prev'),
				swiper = new Swiper($(element).find('.logos-slider'), {
				slidesPerView: 4,
				slidesPerGroup: 4,
				spaceBetween: 160,
				navigation: {
					nextEl: btnNext,
					prevEl: btnPrev,
				},
				breakpoints: {
					1199: {
						spaceBetween: 35,
					},
					767: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						spaceBetween: 10
					}
				}
			});
		});

    }

};

export default logosSlider;
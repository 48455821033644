var scrollToBlock = {
    scrollToBlockInit: function($) {

        var $body = $('body'),
            $aboutWrap = $('body.page-template-about-us'),
            idBlock,
            $header = $('.header'),
            $linkMenu = $('.menu-scrollto-anchor .sub-menu .menu-item:not(.join-us-item) a');

        if (!$aboutWrap.length) {
            return;
        }

        linkMenuHandler();

        $(window).scrollTop(0);

        if (!window.location.hash) {
            linkMenuHandler();
        } else {
            scrollToHandler(window.location.hash);
            linkMenuHandler();
        }

        $body.css('opacity', 1);

        function linkMenuHandler(){
            $linkMenu.on('click', function(e){
                if(!$('body').hasClass('menu-active')){
                    e.preventDefault();
                    scrollToHandler(this.href, true);
                    window.history.pushState('', '', this.href);
                } else {
                    window.history.pushState('', '', this.href);
                    location.reload();
                }
            });
        }

        function scrollToHandler(blockIdEl, flag){
            var $toBlock,
                $headerHeight = $header.innerHeight(),
                k = 0,
                offset = 0;

            $aboutWrap.imagesLoaded( function() {
                if(!flag){
                    $(window).scrollTop(0);
                }
                $body.css('opacity', 1);
                setTimeout(function(){
                    idBlock = blockIdEl.split('#')[1];
                    $toBlock = $('#' + idBlock).offset().top;

                    if($body.hasClass('admin-bar')){
                        k = 32;
                    }

                    if(!$header.hasClass('sticky')){
                        offset = 34;
                    }

                    $('html, body').animate({
                        scrollTop: $toBlock - $headerHeight - k + offset
                    }, 400);
                }, 300);
            });
        }

    }

};

export default scrollToBlock;
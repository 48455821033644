var decorLine = {
    decorLineInit: function($) {
        var $decorLineWrap = $('.animate-block .item .decor'),
            isChromium = window.chrome,
            winNav = window.navigator,
            vendorName = winNav.vendor,
            isOpera = winNav.userAgent.indexOf("OPR") > -1,
            isIEedge = winNav.userAgent.indexOf("Edge") > -1;

        if (!$decorLineWrap.length) {
            return;
        }

        // fix bug on right click on title of news item in Chrome
        if(isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
            $('body').addClass('chrome');
        }

        decorLineHandler();

        $(window).resize(decorLineHandler);

        function decorLineHandler(){
	        $decorLineWrap.each(function(indx, element){
                var $decorLineWrapWidth = $(element).find('.fake-inner').width(),
                    $fakeDecor = $(element).find('.fake-decor');
                $fakeDecor.css('width', $decorLineWrapWidth);
			});
		}

    }

};

export default decorLine;
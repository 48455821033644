var attachFiles = {
    attachFilesInit: function($) {
        var $contactsFormWrap = $('.contacts-form-holder'),
            $uploadLink = $contactsFormWrap.find('.upload-link'),
            $uploadWrap = $contactsFormWrap.find('.upload-file-holder'),
            $filesListWrap2 = $contactsFormWrap.find('.files-list-2'),
            $uploadBtn = $contactsFormWrap.find('.upload-btn'),
            $uploadInput = $contactsFormWrap.find('.upload-input'),
            $hiddenLinkArea = $contactsFormWrap.find('.hidden-link-area'),
            $attachBtn = $contactsFormWrap.find('.attach-btn'),
            $filesListWrap = $contactsFormWrap.find('.files-list'),
            $hiddenFilesListWrap = $contactsFormWrap.find('.hidden-input-type-files'),
            $removeBtn,
            $removeAttachedBtn,
            counter = 1;

        if (!$uploadLink.length) {
            return;
        }

        $uploadLink.on('click', function() {
            showAttachFilesHandler();
        });

        $uploadBtn.on('click', function() {
            uploadFileHandler();
        });

        $attachBtn.on('click', function() {
            if(counter <= 5){
                $('.attach-file-holder').removeClass('disabled');
                attachFileHandler();
            } else {
                $('.attach-file-holder').addClass('disabled');
            }
        });

        function showAttachFilesHandler(){
            $uploadWrap.addClass('active');
        }

        function uploadFileHandler(){
            var fakeUploadText = '<span class="fake-input-file">' + $uploadInput.val() + '<span class="delete-file"></span></span>',
                inputValue = $uploadInput.val(),
                regex = /http(s)?:\/\/.*\.[a-z0-9]{2,4}$/;

            $uploadInput.closest('.input-holder').removeClass('url-error');
            if(inputValue){
                if(regex.test(inputValue)) {
                    var listFiles,
                        arrListFiles;
                    $filesListWrap2.append(fakeUploadText);
                    $removeBtn = $('.delete-file');
                    $removeBtn.off();
                    $removeBtn.on('click', function() {
                        removeFile.call(this);
                    });
                    $hiddenLinkArea.val($hiddenLinkArea.val() + $uploadInput.val() + '\n');
                    listFiles = $hiddenLinkArea.val();
                    arrListFiles = listFiles.split('\n');
                    arrListFiles.pop();
                    $uploadInput.val('');
                } else {
                    $uploadInput.closest('.input-holder').addClass('url-error');
                    return false;
                }
            }
        }

        function removeFile(){
            var indexFile = $(this).closest('.fake-input-file').index(),
                strFiles = $hiddenLinkArea.val(),
                arrFiles = strFiles.split('\n'),
                newStrFiles;

            arrFiles.pop();
            arrFiles.splice(indexFile, 1);
            newStrFiles = arrFiles.join('\n') + '\n';
            $hiddenLinkArea.val(newStrFiles);
            $(this).closest('.fake-input-file').remove();
        }

        function attachFileHandler(){
            if($('.hidden-input-type-files .input-file').length){
                for(var i = 0; i < $('.input-file').length; i++){
                    if($('.input-file')[i].value === ''){
                        $('.input-file')[i].closest('.wpcf7-form-control-wrap').remove();
                        counter--;
                    }
                }
                for(var i = 0; i < $('.input-file').length; i++){
                    $('.input-file')[i].closest('.wpcf7-form-control-wrap').setAttribute('class', 'wpcf7-form-control-wrap file-' + (i + 1));
                    $('.input-file')[i].setAttribute('name', 'file-' + (i + 1));
                }
            }
            $('<input>', {
                'type': 'file',
                'name': 'file-' + counter,
                'class': 'wpcf7-form-control wpcf7-file input-file',
                'accept': '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.avi,.ogg,.m4a,.mov,.mp3,.mp4,.mpg,.wav,.wmv',
                'aria-invalid': 'false',
                'on': {
                    'change': function(event){
                        if(event.target.value != ''){
                            var nameFile = event.target.value.split('\\').pop();
                            var fakeInputFile = '<span class="fake-input-file">' + nameFile + '<span class="delete-attached-file"></span></span>';
                            $filesListWrap.append(fakeInputFile);
                            $removeAttachedBtn = $('.delete-attached-file');
                            $removeAttachedBtn.off();
                            $removeAttachedBtn.on('click', function() {
                                removeAttachedFile.call(this);
                            });
                        }
                    }
                }
            }).appendTo($hiddenFilesListWrap).click().wrap('<span class="wpcf7-form-control-wrap file-' + counter + '"></span>');
            counter++;
        }

        function removeAttachedFile(){
            var indexFile = $(this).closest('.fake-input-file').index();
            if($(this).closest('.fake-input-file').next().hasClass('too-big-error')){
                $(this).closest('.fake-input-file').next().remove();
            }
            $(this).closest('.fake-input-file').remove();
            for(var i = 0; i < $('.input-file').length; i++){
                if(i == indexFile){
                    $('.input-file')[i].closest('.wpcf7-form-control-wrap').remove();
                }
            }
            for(var i = 0; i < $('.input-file').length; i++){
                $('.input-file')[i].closest('.wpcf7-form-control-wrap').setAttribute('class', 'wpcf7-form-control-wrap file-' + (i + 1));
                $('.input-file')[i].setAttribute('name', 'file-' + (i + 1));
            }
            if($('.input-file').length < 5){
                $('.attach-file-holder').removeClass('disabled');
            }
            counter--;
        }

    }

};

export default attachFiles;
var scrollDown = {
    scrollDownInit: function($) {
        var $scrollDownBtn = $('.scroll-down'),
            $heroBlock = $('.hero-slider');

        if (!$scrollDownBtn.length) {
            return;
        }

        $scrollDownBtn.on('click', function() {
            scrollDownHandler.call(this);
        });

        function scrollDownHandler(){
            var $heroBlockTop = $heroBlock.offset().top,
                $heroBlockHeight = $heroBlock.height();
            $('html, body').animate({
                scrollTop: $heroBlockTop + $heroBlockHeight
            }, 400);
        }

    }

};

export default scrollDown;
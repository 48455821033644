var pictureTag = {
    pictureTagInit: function($) {
    	var $body = $('body');

        if (!$body.length) {
            return;
        }

		picturefill();

    }

};

export default pictureTag;
var matchHeightJoin = {
    matchHeightJoinInit: function($) {
        var $sameElem = $('.join-type-block .item .img-holder'),
        	$sameLink = $('.join-type-block .item .same');

        if (!$sameElem.length) {
            return;
        }

        $(function() {
        	$sameElem.matchHeight();
        	$sameLink.matchHeight();
        });

    }

};

export default matchHeightJoin;
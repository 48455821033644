var submenu = {
    submenuInit: function($) {
        var $submenuItem = $('.navbar .menu-item-has-children > a'),
            $submenuWrap = $('.navbar .menu-item-has-children'),
            $header = $('.header'),
            flag = true;

        if (!$submenuItem.length) {
            return;
        }

        submenuEventsTrigger();
        $(window).resize(submenuEventsTrigger);

        function submenuEventsTrigger(){
            if(window.screen.width < 1024){
                $submenuItem.off('click.submenu', handler);
                $submenuWrap.off('mouseenter.submenu', hoverInFunc);
                $submenuWrap.off('mouseleave.submenu', hoverOutFunc);
                $submenuItem.on('click.submenu', handler);
            } else {
                $submenuWrap.off('mouseenter.submenu', hoverInFunc);
                $submenuWrap.off('mouseleave.submenu', hoverOutFunc);
                $submenuItem.off('click.submenu', handler);
                $submenuWrap.on('mouseenter.submenu', hoverInFunc);
                $submenuWrap.on('mouseleave.submenu', hoverOutFunc);
            }
        }

        function hoverInFunc(){
            submenuOnHandler.call(this);
        }

        function hoverOutFunc(){
            submenuOffHandler.call(this);
        }

        function handler(e){
            var $currentTarget = $(e.currentTarget);

            if(!$currentTarget.attr('data-current')){
                e.preventDefault();
                submenuHandler.call(this);
                $currentTarget.attr('data-current', true);
            }
        }

        function submenuHandler(){
            $(this).closest('.menu-item-has-children').toggleClass('active-menu-item');
            $(this).closest('.menu-item-has-children').find('.sub-menu').toggleClass('active').stop(true, true);
        }

        function submenuOnHandler(){
            $(this).closest('.menu-item-has-children').addClass('active-menu-item');
            $(this).closest('.menu-item-has-children').find('.sub-menu').addClass('active').stop(true, true);
            $header.addClass('menu-open');
        }

        function submenuOffHandler(){
            $(this).closest('.menu-item-has-children').removeClass('active-menu-item');
            $(this).closest('.menu-item-has-children').find('.sub-menu').removeClass('active').stop(true, true);
            $header.removeClass('menu-open');
        }

    }

};

export default submenu;
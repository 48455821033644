var burger = {
    burgerInit: function($) {
        var $burgerBtn = $('.burger-btn'),
            $menu = $('.header .navbar'),
            $body = $('body'),
            $header = $('.header'),
            topPosition;

        if (!$burgerBtn.length) {
            return;
        }

        $burgerBtn.on('click', function() {
            removeBodyScroll();
            burgerHandler.call(this);
        });

        function burgerHandler(){
          $(this).toggleClass('active');
          $menu.toggleClass('active');
          $body.toggleClass('menu-active');
        }

        function removeBodyScroll() {
          var body = $('body');
          if (!body.hasClass('menu-active')) {
              topPosition = $(window).scrollTop();
              $('#wrapper').css('top', - topPosition);
              $('#wrapper > *:not(.header)').css('opacity', '0');
          } else {
              setTimeout(function () {
                  $(window).scrollTop(topPosition);
                  $('#wrapper').removeAttr( 'style' );
                  $('#wrapper > *:not(.header)').css('opacity', '1');
              }, 0);
          }
        }

    }

};

export default burger;
var calendarLinks = {
    calendarLinksInit: function($) {
        var $eventFakeLink = $('.simcal-calendar .simcal-event-title'),
            $linkMobile = $(".simcal-calendar .simcal-events-dots"),
            $body = $("body"),
            linkId,
            linkHref,
            addedLink;

        if (!$eventFakeLink.length) {
            return;
        }

        $(".simcal-calendar .simcal-events-dots").off();

        calendarHandlers();

        $(window).on('orientationchange resize', function(){
            $(".simcal-calendar .simcal-events-dots").off();

            calendarHandlers();
        });

        $('.simcal-nav-button').attr('disabled', false);

        intervalHandler();

        function intervalHandler(){
            var monthClasses = $('.simcal-month').attr('class');
            $('.simcal-calendar .simcal-nav-button').on('click', function(){
                var timer = setInterval(function () {
                    if ($('.simcal-month').attr('class') != monthClasses) {
                        $(".simcal-calendar .simcal-events-dots").off();

                        calendarHandlers();

                        $(window).on('orientationchange resize', function () {
                            $(".simcal-calendar .simcal-events-dots").off();

                            calendarHandlers();
                        });
                        intervalHandler();
                        $('.simcal-nav-button').attr('disabled', false);
                        clearInterval(timer);
                        return;
                    }
                }, 100);
            });
        }

        function calendarHandlers() {
            $(".simcal-calendar .simcal-events-dots").off('click.linkmobile').on('click.linkmobile', linkMobileHandler);

            $('.simcal-calendar .simcal-event-title').off('touchstart.linkshandler click.linkshandlerclick').on('touchstart.linkshandler click.linkshandlerclick', function () {
                if ($(window).width() < 768) {
                    mobileCalendarLinksHandler.call(this);
                } else {
                    calendarLinksHandler.call(this);
                }
            });

            $body.off('touchstart.bodyhandler mousedown.bodyhandlerclick').on('touchstart.bodyhandler mousedown.bodyhandlerclick', function (e) {
                if (!$(e.target).parents('.qtip-custom').length && !$(e.target).hasClass('qtip-custom') && $(window).width() < 768) {
                    $('.qtip-custom').remove();
                }
            });
        }

        function linkMobileHandler(){
            if($(window).width() < 768){
                showDate.call(this);
                mobileTooltipHandler.call(this);
            }
        }

        function calendarLinksHandler() {
            var self = this;
            setTimeout(function(){
                linkId = $(self).closest('.simcal-tooltip').data('hasqtip');
                openInNewTab($('#qtip-' + linkId + ' .qtip-content a').first().attr('href'));
            }, 500);
        }

        function mobileCalendarLinksHandler() {
            var self = this;
            setTimeout(function(){
                linkHref = $(self).closest('.simcal-event').find('.simcal-tooltip-content a').attr('href');
                openInNewTab(linkHref);
            }, 500);
        }

        function openInNewTab(url) {
            var win = window.open(url, '_blank');
            win.focus();
        }

        function mobileTooltipHandler(){
            if($(this).closest('.simcal-day').hasClass('simcal-day-has-events')){
                var cloneDay,
                    cloneEvents;
                $('.qtip-custom').remove();
                cloneEvents = $(this).closest('.simcal-day').find('.simcal-events').clone();
                $(this).closest('.simcal-day').find('.simcal-events').remove();
                cloneEvents.appendTo($(this).closest('.simcal-day > div'));
                cloneDay = $(this).closest('.simcal-day').find('.simcal-events').html();
                $body.append('<div class="qtip-custom"><ul class="events-list">' + cloneDay + '</ul></div>');
                $('.qtip-custom .simcal-event-title').off('click.mobiletooltip').on('click.mobiletooltip', mobileCalendarLinksHandler);
            } else {
                $('.qtip-custom').remove();
            }
        }

        function showDate(){
            var startEventText = $(this).closest('.simcal-day-has-events').find('.simcal-event-start-date').html();

            $('.simcal-calendar .date-event-mobile').remove();
            $(this).closest('.simcal-day-has-events').find('.simcal-events').prepend('<li class="date-event-mobile">' + startEventText + '</li>');
        }
    }

};

export default calendarLinks;
var blockAnimate = {
    blockAnimateInit: function($) {
        var $blockAnimateWrap = $('.wow');

        if (!$blockAnimateWrap.length) {
            return;
        }

        if ($blockAnimateWrap.closest('.animate-block').length) {
            if((navigator.userAgent.indexOf('Trident') != -1 && navigator.userAgent.indexOf('MSIE') == -1) || navigator.userAgent.indexOf('Edge') !== -1){
                var wow = new WOW({
                    live: false,
                    callback: function callback(box) {
                        $(box).addClass('animate-done');
                    }
                });
                wow.init();
            } else {
                var wow = new WOW({
                    callback: function callback(box) {
                        $(box).addClass('animate-done');
                    }
                });
                wow.init();
                
            }
        } else {
            if((navigator.userAgent.indexOf('Trident') != -1 && navigator.userAgent.indexOf('MSIE') == -1) || navigator.userAgent.indexOf('Edge') !== -1){
                if($('.events-block').length){
                    var wow = new WOW({
                        live: false
                    });
                    wow.init();
                }
            } else {
                var wow = new WOW();
                wow.init();
            }
        }
    }

};

export default blockAnimate;
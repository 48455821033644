var stickyHeader = {
    stickyHeaderInit: function($) {
        var $header = $('.header'),
            $body = $('body');

        if (!$header.length) {
            return;
        }

        $(window).on('scroll', stickyHeaderHandler);

        function stickyHeaderHandler(){
            if($(document).scrollTop() > 30){
              $header.addClass('sticky');
            } else if($(document).scrollTop() <= 30 && !$body.hasClass('menu-active')){
              $header.removeClass('sticky');
            }

            if($(document).scrollTop() > 100){
              $header.addClass('header-shadow');
            } else {
              $header.removeClass('header-shadow');
            }
        }

    }

};

export default stickyHeader;
var buttonsList = {
    buttonsListInit: function($) {
        var $buttonsListBtn = $('.meta-block .dot-btn'),
            $buttonsHolder = $('.btns-holder'),
            $body = $('body');

        if (!$buttonsListBtn.length) {
            return;
        }

        $buttonsListBtn.on('click', function() {
            buttonsListHandler.call(this);
        });

        $body.on('touchstart click', function(e){
            if($(window).width() < 768){
                if(!$(e.target).parents('.btns-holder').length){
                    $buttonsHolder.removeClass('active');
                }
            }
        });

        function buttonsListHandler(){
          $(this).closest('.btns-holder').toggleClass('active');
        }

    }

};

export default buttonsList;
var filterAll = {
    filterAllInit: function($) {
        var $filterAllBtn = $('.filter-holder .view-all'),
            $filterItem = $('.filter > li'),
            $body = $('body'),
            $filterWrap = $('.filter-holder');

        if (!$filterAllBtn.length) {
            return;
        }

        $filterAllBtn.on('click', function() {
            filterAllHandler();
        });

        $body.on('click', function(e){
            if($(window).width() > 1023){
                if(!$(e.target).parents('.filter-holder').length){
                    $filterWrap.removeClass('active');
                }
            }
        });

        function filterAllHandler(){
          $filterWrap.addClass('active');
        }

    }

};

export default filterAll;
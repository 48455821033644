var activeLanguage = {
    activeLanguageInit: function($) {
        var $langWrap = $('.lang-hold'),
            activeLangHtml = $('.lang-list li.active').html();

        if (!$langWrap.length) {
            return;
        }

        $langWrap.html(activeLangHtml);

    }

};

export default activeLanguage;